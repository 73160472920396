.accordion-content {
    max-height: 0;
    padding-left: 1rem;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
  }

  .accordion-content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
  }

  .accordion {
    width: fit-content;
  }
  .accordion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: #21abd4;
    padding: 1rem 0.5rem;
    cursor: pointer;
  }

  .accordion-header-small {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1rem;
    /* background-color: #21abd4; */
    padding: 1rem 0.5rem;
    cursor: pointer;
  }

  .accordion-title {
    font-weight: bold;
    font-size: 1rem;
  }

  .accordion-header-jsx {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .selected-icon {
    width: 1rem;
    font-size: 1rem;
  }