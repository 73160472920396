:root {
    /* --red30: #6ecaf5; */
    --text-color-1: white;
    --text-color-2: black;
    --red30: white;
    --theme-secondary: white;
    --bg: #aaa7fe;
    --padding-sides: 5rem;
    --font-theme: mario;
    --footer-height: 5rem;
    --soft-black: #222831;
    --font-size-percentage: 100%;
    --gray10: #DDDDDD;
    --blue-gray: #30475e;
    --orange: #f8920cff;
    --blue30: #21abd4;
    --red80: #DD3444;
    --green20: #8BE78B;
  }
 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* font-family: var(--font-theme); */
    /* zoom: 0.91; */
    /* font-size: 0.8rem; */
 }

  .App {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: var(--bg);
    color: var(--text-color-2);
    min-height: 100dvh;
    font-size: var(--font-size-percentage);
    /* font-size-adjust: 1; */
  }

  .button-none {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-decoration: underline;
  }
  .text-section {
    /* color: black; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .page-container {
    width: 50%;
    position: relative;
    /* min-height: 100dvh; */
    padding-bottom: var(--footer-height);
  }

  .create-container > *{
    /* border-bottom: 0.5rem solid blue; */
  }

  .create-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 1rem;
    padding: 1rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .text-small {
    font-size: 0.7rem;
}

  .row.start {
    justify-content: flex-start;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-theme);
    /* position: fixed; */
    /* width: 100%; */
    /* top:0; */
    /* left:0; */
    /* border: 1px solid blue; */
    /* align-items: center; */
  }

  .hamburger-menu {
    /* align-self: flex-end; */
    /* padding-left: 5rem; */
    justify-self: flex-end;
    /* margin-left: 5rem; */
    border: 1rem solid black;
    z-index: 1000;
    right: 0;
    bottom: 0;
    margin: 0.5rem;
    width: 3rem;
    height: 3rem;
    background-color: var(--red30);
    border: none;
    border-radius: 0.5rem;
    /* transition: 1s; */
}

.header-title {
    flex: 1;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--red30);
    height: 10dvh;
}

.highlight-fill {
    background-color: var(--red30);
    color: black;
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
}

.fit-content {
    width: fit-content;
}

.leaderboard-wrapper {
    /* height: 90dvh; */
    display: flex;
    flex-direction: column;
    flex: 1;
}

.signin-container {
    display: flex;
    flex-direction: column;
    width: 20rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    height: 90dvh;
}

.signedin-container {
    display: flex;
    flex-direction: row;
    border-left: 5px solid grey;
    padding: 0.5rem;
    gap: 1rem;
}

.task-screen-containter {
    /* padding: 0rem 1rem; */
    /* align-content: center; */
}
input, select {
    width: 100%;
    padding: 15px;
    margin: 10px 0px;
    border-radius: 0.4rem;
    border: 1px solid gray;
    /* font-size: 20px; */
}

.footer {
    position: absolute;
    bottom: 0;

    background-color: aqua;
    width: 100%;
    height: var(--footer-height);
    background-color: var(--soft-black);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* background-image: url('./mario_brick.png');
    background-size: 48px; */
    /* padding: 1rem 0rem; */
}

.footer-content {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.footer-description {
    display: flex;
    flex-direction: column;
}

.johan-plug {
    font-size: 0.6rem;
    display: flex;
    align-items: flex-end;
    gap: 0.2rem;
}

.icon-container {
    width: 0.6rem;
}

.icon-container svg {
    fill: white;
}

.johan-plug a {
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 600px) {
    .page-container {
        width: 100%;
    }
    .create-container {
        padding: 0.5rem;
    }
    .task-screen-containter {
        padding: 0rem 0.5rem;
    }
    .footer-content {
        width: 100%;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }
    .footer-description {
        align-items: center;
    }
}