.room-item {
    border: 0.5rem solid var(--red30);
    border-radius: 1rem;
    padding: 1rem;
}

.room-list {
    align-self: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 600px) {
    .room-list {
        grid-template-columns: 1fr;
    }
}