.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    /* background-color: var(--bg); */
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: var(--bg); */
 }

 .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.close { 
    background-color: transparent;
    border: 0;
    font-size: 20px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.2s ease-in;
}

.close:hover {
    background-color: var(--red30);
    opacity: 0.7;
}