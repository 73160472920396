.list-item-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.item-index {
    min-width: 2rem;
    /* border: 1px solid green; */
}

.delete-section-row {
    /* align-self: flex-end; */
    flex: 1;
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    padding: 0.1rem 0rem;
}