.menu-bar {
    font-size: 2rem;
    background: none;
    padding: 1rem 1rem;
    font-weight: bold;
    cursor: pointer;
}

.menu-bar.active {
    background-color: white;
    color: #5bcbf5;
    border: 0.5rem solid #5bcbf5;
    border-radius: 1rem;
    transition: background-color 350ms;
    transition: color 350ms;
}

.sidebar {
    z-index: 100;
    height: 100dvh;
    display: flex;
    background-color: #5bcbf5;
    /* background-image: url('../../mario_brick.png');
    background-size: 32px; */
    position: fixed;
    justify-content: flex-start;
    flex-direction: column;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: 850ms;
    list-style: none;
    color: white;
}

.sidebar.active {
    transform: translateX(0%);
    transition: 350ms;
}