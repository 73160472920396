.users-display {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid black; */
    padding: 1rem 0rem;
}

.user-list {
    /* align-self: center; */
}

/* .user-item:nth-child(even), .room-item:nth-child(odd) {
    background-color: gray;
} */

.user-item, .room-item {
    padding: 0rem 0.5rem;
}

.input-container {
    /* border: 1px solid black; */
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.2rem
}

.user-creation-container {
    border: 5px solid #D3D3D3;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.room-user-item {
    padding: 0rem 0.5rem;
}

.room-member {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.room-member-container {
    width: fit-content;
    color: var(--text-color-2)
}

.delete-item-section {
    padding-top: 1rem;
}

@media screen and (max-width: 600px) {
    .user-creation-container {
        padding: 0.2rem;
    }
}